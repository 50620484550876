<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="gender"
                :name="$t('labels.gender')"
              >
                <b-form-group
                  :label="$t('labels.gender')"
                >
                  <v-select
                    v-model="form.gender"
                    :dir="$store.state.appConfig.layout.direction"
                    :options="genders"
                    :reduce="item => item.id"
                    label="name"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="country_id"
                :name="$t('country')"
                rules="required"
              >
                <b-form-group
                  :label="$t('country')"
                >
                  <v-select
                    v-model="form.country_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="countries"
                    :reduce="item => item.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="referee_type_id"
                :name="$t('labels.referee_type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.referee_type')"
                >
                  <v-select
                    v-model="form.referee_type_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="referee_types"
                    :reduce="item => item.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.dob')"
                vid="dob"
              >
                <b-form-group>
                  <DatePicker
                    v-model="form.dob"
                    :label="$t('labels.dob')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.year_become_ref')"
                vid="year_become_ref"
                rules="required|numeric"
              >
                <b-form-group
                  :label="$t('labels.year_become_ref')"
                >
                  <b-form-input
                    v-model="form.year_become_ref"
                    :placeholder="$t('labels.year_become_ref')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.year_become_international')"
                vid="year_become_international"
                rules="required|numeric"
              >
                <b-form-group
                  :label="$t('labels.year_become_international')"
                >
                  <b-form-input
                    v-model="form.year_become_international"
                    :placeholder="$t('labels.year_become_international')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.number_of_matches')"
                vid="number_of_matches"
                rules="required|numeric"
              >
                <b-form-group
                  :label="$t('labels.number_of_matches')"
                >
                  <b-form-input
                    v-model="form.number_of_matches"
                    :placeholder="$t('labels.number_of_matches')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="avatar"
                :name="$t('labels.image')"
              >
                <ImagePreview
                  v-model="avatar"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <!-- media -->
            <b-col cols="12">
              <hr>
              <div class="d-flex align-items-center justify-content-between">
                <h6>
                  {{ $t('labels.media') }}
                </h6>
                <b-button
                  variant="primary"
                  class="btn-icon"
                  @click.prevent="addFile"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
              <div
                v-for="(item, index) in files"
                :key="index"
                class="pt-1"
              >
                <div
                  :ref="`files${index}`"
                  class="d-flex align-items-start justify-content-between"
                >
                  <div class="flex-grow-1">
                    <b-form-file
                      size="lg"
                      :placeholder="$t('labels.drop_files_here_or_click_to_upload')"
                      drop-placeholder="Drop file here..."
                      @input="onChangeFile($event, index)"
                    />
                  </div>
                  <div
                    style="width: 50px; height: 50px; border-radius: 4px;overflow: hidden;background: #fff; margin-right: 10px;"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <img
                      style="width: 100%; height: 100%; object-fit: cover;"
                      class="img-fluid"
                      :src="item.file"
                    >
                  </div>
                  <b-button
                    variant="danger"
                    class="btn-icon ml-1"
                    @click="removeFile(index)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </div>
              </div>
            </b-col>
            <!-- checkbox -->
            <b-col cols="12">
              <br>
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      avatar: null,
      countries: [],
      referee_types: [],
      form: {
        name: {
          en: null,
          ar: null,
        },
        gender: null,
        country_id: null,
        dob: null,
        year_become_ref: null,
        year_become_international: null,
        number_of_matches: null,
        referee_type_id: null,
        avatar: null,
        status: 'active',
        media: [],
      },
      files: [],
    }
  },
  computed: {
    genders() {
      return [
        {
          id: 'male',
          name: this.$t('labels.male'),
        },
        {
          id: 'female',
          name: this.$t('labels.female'),
        },
      ]
    },
  },
  watch: {
    avatar(val) {
      if (val) {
        this.form.avatar = val
      } else {
        this.form.avatar = 'delete'
      }
    },
  },
  created() {
    this.getCountries()
    this.getRefereeTypes()
    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getCountries() {
      this.axios.get('/lists/Country')
        .then(res => {
          this.countries = res.data
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    getRefereeTypes() {
      this.axios.get('/lists/referee/type')
        .then(res => {
          this.referee_types = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            gender: data.gender,
            country_id: data.country_id,
            dob: data.dob,
            year_become_ref: data.year_become_ref,
            year_become_international: data.year_become_international,
            number_of_matches: data.number_of_matches,
            referee_type_id: data.referee_type_id,
            status: data.status,
          }
          this.avatar = data.avatar
          this.files = data?.media_list.map(x => ({
            id: x.id,
            file: x.file,
          }))
          const $attachments = _.cloneDeep(data.media_list)
          this.form.media = $attachments
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
        media: [
          ...this.files,
        ],
      }

      if (!($form.avatar instanceof File)) {
        delete $form.avatar
      }
      $form.media.forEach(x => {
        if (!(x.file instanceof File) && x.file !== 'delete') {
          // eslint-disable-next-line no-param-reassign
          delete x.file
          // eslint-disable-next-line no-param-reassign
          delete x.id
        }
      })
      return $form
    },
    removeFile(index) {
      const file = this.files[index]
      this.files[index] = {
        ...file,
        id: file.id,
        file: 'delete',
      }
      this.$refs[`files${index}`][0].remove()
    },
    addFile() {
      this.files.push({
        file: null,
        id: null,
      })
    },
    onChangeFile(e, index) {
      const file = e
      this.files[index].file = file
    },
  },

}
</script>
